import React from "react";
import Avatar from "@material-ui/core/Avatar";
import CssBaseline from "@material-ui/core/CssBaseline";
import Link from "@material-ui/core/Link";
import Paper from "@material-ui/core/Paper";
import Grid from "@material-ui/core/Grid";
import LockOutlinedIcon from "@material-ui/icons/LockOutlined";
import Typography from "@material-ui/core/Typography";
import { withStyles } from "@material-ui/core/styles";
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";
import {
  withRouter,
  connect,
  TEXTFIELD,
  ToastContainer,
  toast,
  Axios,
  PASSWORDFIELD,
  SUBMIT,
  SELECT,
  RESET,
  CircularProgress,
  Logo,
  Row,
  Col,
  ApiUrl,
  PHONECODES,
} from "../../components";

import {
  hideMessage,
  showAuthLoader,
  userSignInSuccess,
  hideAuthLoader,
} from "actions/Auth";
import { Container } from "@material-ui/core";
import WhyOurBrand from "../../components/auth/authBody/WhyOurBrand/Index";
import ImgWithText from "../../components/auth/authBody/ImageWithText/Index";
import HowWeWork from "../../components/auth/authBody/HowWeWork/Index";
import OurClient from "../../components/auth/authBody/OurClient/Index";
import GetStartedSteps from "../../components/auth/authBody/GetStartedSteps/Index";
import Faq from "../../components/auth/authBody/Faq/Index";
import Header from "../../components/auth/authBody/Header";
import Footer from "../../components/auth/authBody/Footer/Index";
import { COUNTRY_OPTIONS } from "../../app/routes/common/worldCountries";
import { SearchSelectInput } from "../../app/routes/common/inputFields";

import { useParams } from "react-router";
const { API_FORGOT_PASSWORD, API_RESET_PASSWORD } = ApiUrl;
// const COLORS = "#f15931";
const COLORS = "#4A863E";

const useStyles = (theme) => ({
  root: {
    height: "100vh",
  },
  image: {
    backgroundImage: "url(https://source.unsplash.com/random)",
    backgroundRepeat: "no-repeat",
    backgroundColor:
      theme.palette.type === "light"
        ? theme.palette.grey[50]
        : theme.palette.grey[900],
    backgroundSize: "cover",
    backgroundPosition: "center",
  },
  paper: {
    margin: theme.spacing(8, 4),
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
  },
  avatar: {
    margin: theme.spacing(1),
    backgroundColor: theme.palette.secondary.main,
  },
  form: {
    width: "100%", // Fix IE 11 issue.
    marginTop: theme.spacing(1),
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
  },
});

class SignIn extends React.Component {
  constructor() {
    super();
    this.state = {
      email: "",
      password: "",
      confirmPassword: "",
      otp: "",
      mobileNumber: "",
      countryCode: "🇺🇸 +1",
      queryvalue: "",
      queryemail: "",
      value: 0,
      resetForm: false,
      disabled: false,
    };
  }

  handleChange = (event) => {
    this.setState({ [event.target.name]: event.target.value });
  };
  handleChange1 = (event, newValue) => {
    // console.log(newValue, "newValue");
    this.setState({ value: newValue });
  };
  forgotPassword = (e) => {
    e.preventDefault();
    this.setState({
      loading: true,
      disabled: true,
    });

    const { email, mobileNumber, countryCode } = this.state;
    const data = {
      email,
      mobileNumber,
      countryCode,
    };

    Axios.post(API_FORGOT_PASSWORD, data)
      .then((response) => {
        const { message, status } = response.data;
        if (status === "success") {
          toast.success(message);
          this.setState({ resetForm: true });
        } else {
          toast.error(message);
        }

        this.setState({
          loading: false,
          disabled: false,
        });
      })
      .catch((err) => {
        let error = err.response;

        this.setState({
          loading: false,
          disabled: false,
          resetForm: false,
        });
      });
  };
  resetPassword = (e) => {
    e.preventDefault();
    this.setState({
      loading: true,
      disabled: true,
    });

    const {
      email,
      password,
      confirmPassword,
      otp,
      queryemail,
      mobileNumber,
      countryCode,
    } = this.state;

    Axios.post(API_RESET_PASSWORD, {
      email: email ? email : queryemail,
      password,
      mobileNumber,
      confirmPassword,
      otp,
      countryCode,
      OTP: otp,
    })
      .then((response) => {
        const { message, status } = response.data;
        if (status === "success") {
          this.setState({ resetForm: false });
          this.props.history.push("/");
          toast.success(message);
        } else {
          toast.error(message);
        }

        this.setState({
          loading: false,
          disabled: false,
        });
      })
      .catch((err) => {
        let error = err.response;

        this.setState({
          loading: false,
          disabled: false,
          resetForm: false,
        });
      });
  };
  reset = () => {
    this.setState({
      email: "",
      password: "",
      confirmPassword: "",
      otp: "",
      resetForm: false,
      disabled: false,
    });
  };

  componentDidMount() {
    const query = new URLSearchParams(this.props.location.search);
    const queryvalue = query.get("resetpass");
    const queryemail = query.get("email");
    this.setState({ queryvalue: queryvalue, queryemail: queryemail });
    // const { editId } = match.params;
    this.setState({ loading: false });
  }

  render() {
    const {
      email,
      password,
      confirmPassword,
      otp,
      resetForm,
      queryvalue,
      queryemail,
      loading,
      value,
      mobileNumber,
      countryCode,
      disabled,
    } = this.state;
    // console.log(queryemail, "queryvalue");
    const { classes } = this.props;

    return (
      <Grid component="main">
        <Header />
        <section
          container
          className="py-5 MainAccountForm position-relative d-flex align-items-center justify-content-center"
          style={{
            backgroundImage: 'url("../images/accountbg1.png")',
            backgroundSize: "cover",
            backgroundPosition: "center",
            minHeight: "100vh",
            zIndex: "9",
          }}
        >
          <Container fixed>
            <Grid container>
              <Grid
                item
                md={4}
                container
                justify="center"
                spacing={0}
                direction="column"
                alignItems="center"
              >
                {/* <img
                    src={require("assets/images/logo1.png")}
                    className="logo-login"
                  /> */}
                <div className="bannerContent">
                  <h2 className="text-white m-0 pb-3">
                    Unlock a <span className="theme-clr">new revenue </span>
                    stream
                  </h2>
                  <p className="text-white">
                    tucsoneats global platform gives you the flexibility,
                    visibility and customer insights you need to connect with
                    more customers. Partner with us today.
                  </p>
                </div>
              </Grid>
              <Grid
                item
                md={5}
                // component={Paper}
                elevation={6}
                className="ml-auto"
                square
                alignContent="center"
                // style={{ maxHeight: "100vh", overflow: "scroll" }}
              >
                <div className="accountForm bg-white">
                  {/* <div className={classes.paper}> */}
                  {/* <Avatar className={classes.avatar}>
              <LockOutlinedIcon />
            </Avatar> */}
                  <Typography className="form-head" component="h1" variant="h5">
                    {!resetForm ? "Forgot Password" : "Reset Password"}
                  </Typography>
                  {!resetForm && !queryvalue ? (
                    <Row>
                      <Col sm="12" md={12}>
                        <form
                          className={classes.form}
                          noValidate
                          onSubmit={(e) => this.forgotPassword(e)}
                        >
                          <Tabs
                            value={value}
                            onChange={this.handleChange1}
                            TabIndicatorProps={{
                              style: { background: COLORS, height: "4px" },
                            }}
                            textColor="black"
                            variant="fullWidth"
                            centered
                            className="dumpster-tabs mb-4"
                          >
                            <Tab
                              label="Phone"
                              style={{ color: "black", fontWeight: 600 }}
                            />

                            <Tab
                              label="Email"
                              style={{ color: "black", fontWeight: 600 }}
                            />
                          </Tabs>
                          {value == 1 ? (
                            <TEXTFIELD
                              label="Enter Email"
                              fullWidth
                              name="email"
                              onChange={this.handleChange}
                              value={email}
                              errors={{}}
                              variant="outlined"
                            />
                          ) : (
                            <>
                              <label className="form-label">
                                Enter Phone no.
                              </label>
                              <div className="phoneInput d-flex">
                                <div className="">
                                  <SearchSelectInput
                                    label=""
                                    value={countryCode}
                                    name={"countryCode"}
                                    onChange={this.handleChange}
                                    data={COUNTRY_OPTIONS}
                                    // errors={errors}
                                  />
                                </div>
                                <TEXTFIELD
                                  // label="Enter Phone no."
                                  fullWidth
                                  type="number"
                                  name="mobileNumber"
                                  onChange={this.handleChange}
                                  value={mobileNumber}
                                  errors={{}}
                                  // variant="outlined"
                                />
                              </div>
                            </>
                          )}
                          {/* <TEXTFIELD
                            label="Enter Email"
                            fullWidth
                            name="email"
                            onChange={this.handleChange}
                            value={email}
                            errors={{}}
                            variant="outlined"
                          /> */}

                          <SUBMIT title="Submit" disabled={disabled} />
                          <Grid container>
                            <Grid item>
                              <Link
                                href="#/signin"
                                className="form-link"
                                variant="body2"
                              >
                                {"Go To Login"}
                              </Link>
                            </Grid>
                          </Grid>
                        </form>
                      </Col>
                    </Row>
                  ) : (
                    <Row>
                      <Col sm="12">
                        <form
                          className={classes.form}
                          noValidate
                          onSubmit={(e) => this.resetPassword(e)}
                        >
                          <PASSWORDFIELD
                            label="Password"
                            name="password"
                            onChange={this.handleChange}
                            value={password}
                            errors={{}}
                            variant="outlined"
                          />
                          <PASSWORDFIELD
                            label="Confirm Password"
                            name="confirmPassword"
                            onChange={this.handleChange}
                            value={confirmPassword}
                            errors={{}}
                            variant="outlined"
                          />
                          <TEXTFIELD
                            label="OTP"
                            name="otp"
                            onChange={this.handleChange}
                            value={otp}
                            errors={{}}
                            variant="outlined"
                          />

                          <Row>
                            <Col md="6" xs="12">
                              <SUBMIT title="Update" disabled={disabled} />
                            </Col>
                            <Col md="6" xs="12">
                              <RESET onClick={this.reset} />
                            </Col>
                          </Row>
                          <Grid container>
                            <Grid item xs>
                              <Link
                                className="form-link"
                                href="#"
                                onClick={(e) => {
                                  e.preventDefault();
                                  this.forgotPassword(e);
                                }}
                                variant="body2"
                              >
                                Resend OTP
                              </Link>
                            </Grid>
                            <Grid item>
                              <Link
                                className="form-link"
                                href="#/signin"
                                variant="body2"
                              >
                                {"Go to Login"}
                              </Link>
                            </Grid>
                          </Grid>
                        </form>
                      </Col>
                    </Row>
                  )}
                  <ToastContainer />
                  {/* </div> */}
                </div>
              </Grid>
            </Grid>
          </Container>
          {/* <Grid
          item
          xs={false}
          sm={4}
          md={4}
          container
          justify="center"
          spacing={0}
          direction="column"
          alignItems="center"
          // justify="center"
        >
          <img
            src={require("assets/images/logo1.png")}
            className="logo-login"
          />
        </Grid> */}
          {/* <Grid
          item
          xs={12}
          sm={8}
          md={8}
          component={Paper}
          elevation={6}
          square
          alignContent="center"
          style={{ maxHeight: "100vh", overflow: "scroll" }}
        ></Grid> */}
        </section>
        <WhyOurBrand />
        <ImgWithText />
        <HowWeWork />
        <OurClient />
        <GetStartedSteps />
        <Faq />
        <Footer />
        <CssBaseline />
      </Grid>
    );
  }
}

const mapStateToProps = ({ auth }) => {
  const { loader, alertMessage, showMessage, authUser } = auth;
  return { loader, alertMessage, showMessage, authUser };
};

export default connect(mapStateToProps, {
  hideMessage,
  showAuthLoader,
  userSignInSuccess,
  hideAuthLoader,
})(withStyles(useStyles)(SignIn));
