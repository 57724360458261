import React from "react";
import { Container, Grid } from "@material-ui/core";

const GetStartedSteps = () => {
  return (
    <>
      <section
        className="imgWithText py-5"
        style={{ backgroundColor: "#fff8f2" }}
      >
        <Container fixed>
          <Grid
            container
            spacing={3}
            alignItems="center"
            justifyContent="space-between"
          >
            <Grid item md={6} xs={12} className="img-wrpper text-center">
              <img src="../images/iwt2.png" alt="" className="img-fluid" />
            </Grid>
            <Grid item md={5} xs={12}>
              <div className="section-header">
                <h2 className="fw-bold m-0 pb-2">
                  Get started in just{" "}
                  <span className="theme-clr"> 3 steps</span>
                </h2>
                <ul className="list-unstyled pl-0 mb-0">
                  <li className="py-1">
                    <p className="m-0">Tell us about your restaurant.</p>
                  </li>
                  <li className="py-1">
                    <p className="m-0">Upload your menu.</p>
                  </li>
                  <li className="py-1">
                    <p className="m-0">
                      Access Restaurant Dashboard and go live!
                    </p>
                  </li>
                </ul>
              </div>
            </Grid>
          </Grid>
        </Container>
      </section>
    </>
  );
};
export default GetStartedSteps;
