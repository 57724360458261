import React from "react";
import { Container, Grid } from "@material-ui/core";

const OurClient = () => {
  return (
    <>
      <section
        className="imgWithText py-5"
        style={{ backgroundImage: 'url("../images/accountbg.png")' }}
      >
        <Container fixed>
          <Grid
            container
            spacing={3}
            alignItems="center"
            justifyContent="space-between"
          >
            <Grid item md={5} xs={12}>
              <div className="section-header">
                <h2 className="fw-bold m-0 pb-2">
                  "Tucson Eats extends our{" "} 
                  <span className="theme-clr">
                    {" "}
                    brand awareness to neighborhoods
                  </span>{" "}
                  that wouldn't normally be exposed to us."{" "}
                </h2>
                <p className="m-0 py-2">
                  <i>2813 E</i>
                </p>
                <p className="py-2 m-0">Broadway Blvd Tucson, AZ85716</p>
              </div>
            </Grid>
            <Grid item md={7} xs={12} className="img-wrpper text-center">
              <img src="../images/iwt1.png" alt="" className="img-fluid" />
            </Grid>
          </Grid>
        </Container>
      </section>
    </>
  );
};
export default OurClient;
