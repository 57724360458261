import { Container } from "@material-ui/core";
import React from "react";

const Header = () => {
  return (
    <>
      <header className="sticky-top site-header  bg-white shadow-sm py-2">
        <Container fixed>
          <div className="d-flex align-items-center justify-content-between">
            <a className="logo" href="https://tucsoneats.com">
              {/* <img src="../images/logo.png" alt="" className="img-fluid" /> */}
              <img src="../images/newlogo.png" alt="" className="new-logo" />
            </a>
            <div className="right-side">
              <a
                href="https://tucsoneats.com/?showLogin=true"
                className="common-btn d-flex align-items-center justify-content-center rounded-pill"
              >
                Customer Login
              </a>
            </div>
          </div>
        </Container>
      </header>
    </>
  );
};
export default Header;
