import React, { Fragment } from "react";
import {
  withRouter,
  connect,
  TEXTFIELD,
  toast,
  Axios,
  PASSWORDFIELD,
  Row,
  Col,
  SUBMIT,
  RESET,
  CircularProgress,
} from "../components";
import { API_LOGIN } from "../ApiUrl";
import "./includes/css/style.css";

import {
  hideMessage,
  showAuthLoader,
  userSignInSuccess,
  hideAuthLoader,
} from "actions/Auth";

class SignIn extends React.Component {
  constructor() {
    super();
    this.state = {
      email: "",
      password: "",
      disabled: false,
    };
  }

  handleChange = (event) => {
    this.setState({ [event.target.name]: event.target.value });
  };
  loginUser = (e) => {
    e.preventDefault();
    this.setState({
      loading: true,
      disabled: true,
    });
    this.props.showAuthLoader();
    const { email, password } = this.state;
    const formData = {
      email: email.trim(),
      password: password.trim(),
    };
    Axios.post(API_LOGIN, formData)
      .then((response) => {
        let data = response.data;

        if (data.status == "failure") {
          toast.error(data.message, {
            toastId: "customId",
          });
        } else {
          data = data.data;

          localStorage.setItem("token", data.token);
          localStorage.setItem("_id", data._id);
          localStorage.setItem("allDetails", data);
          localStorage.setItem("name", data.name);
          localStorage.setItem("role", data.role);
          localStorage.setItem("categories", data.categories[0]);
          localStorage.setItem("user_id", data.token);
          localStorage.setItem("restaurantStatus", data.restaurantStatus);
          this.props.userSignInSuccess(data.token);
          toast.success("Login successfully.");
          setTimeout(() => {
            this.props.history.push("/app/dashboard/index");
          }, 1000);
        }
        this.setState({
          loading: false,
          disabled: false,
        });
        this.props.hideAuthLoader();
      })
      .catch((err) => {
        console.log(err, 32);
        // let error = err.response;
        // if (error !== undefined) {
        //   toast.error(error.data.message);
        // } else {
        //   toast.error("server error, Please try after some time.", {
        //     toastId: "customId",
        //   });
        // }
        // this.setState({
        //   loading: false,
        //   disabled: false,
        // });
        // this.props.hideAuthLoader();
      });
  };

  reset = () => {
    this.setState({
      email: "",
      password: "",
    });
  };

  render() {
    const { email, password, loading, disabled } = this.state;

    return (
      <div className="app-login-content">
        {loading && (
          <div className="loader-view">
            <CircularProgress />
          </div>
        )}
        <Row>
          <form onSubmit={(e) => this.loginUser(e)}>
            <Col xm="12">
              <TEXTFIELD
                label="Enter Email"
                fullWidth
                name="email"
                onChange={this.handleChange}
                value={email}
                errors={{}}
              />
            </Col>
            <Col xm="12">
              <PASSWORDFIELD
                label="Password"
                fullWidth
                name="password"
                onChange={this.handleChange}
                value={password}
                errors={{}}
              />
            </Col>
            <Col>
              <Row>
                <Col md="4">
                  <SUBMIT title="Sign In" disabled={disabled} />
                </Col>
                <Col md="4" xs="12">
                  <RESET onClick={this.reset} />
                </Col>
              </Row>
            </Col>
          </form>
        </Row>
      </div>
      
    );
  }
}

const mapStateToProps = ({ auth }) => {
  const { loader, alertMessage, showMessage, authUser } = auth;
  return { loader, alertMessage, showMessage, authUser };
};

export default connect(mapStateToProps, {
  hideMessage,
  showAuthLoader,
  userSignInSuccess,
  hideAuthLoader,
})(withRouter(SignIn));
