import React from "react";
import { Redirect, Route, Switch, asyncComponent } from "../../../components";
const Customers = ({ match }) => {
  return (
    <div className="app-wrapper">
      <Switch>
        <Redirect
          exact
          from={`${match.url}/`}
          to={`${match.url}/profile/edit`}
        />
        <Route
          path={`${match.url}/profile/edit`}
          component={asyncComponent(() => import("./routes/profile"))}
        />
        <Route
          path={`${match.url}/password-update`}
          component={asyncComponent(() => import("./routes/password-update"))}
        />
        <Route
          path={`${match.url}/make-toprated`}
          component={asyncComponent(() => import("./routes/make-toprated"))}
        />
        <Route
          component={asyncComponent(() =>
            import("app/routes/extraPages/routes/404")
          )}
        />
      </Switch>
    </div>
  );
};

export default Customers;
