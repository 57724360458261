import { Container, Grid } from "@material-ui/core";
import React from "react";
import { Link } from "react-router-dom";
const Footer = () => {
  return (
    <>
      <footer
        className="site-footer pt-5 pb-2"
        style={{ backgroundColor: "#111" }}
      >
        <Container fixed>
          <Grid
            container
            spacing={3}
            alignItems="center"
            justifyContent="space-between"
          >
            <Grid item md={4} xs={12} className="">
              <h4 className="footHead m-0 pb-2 text-white">Company</h4>
              <ul className="list-unstyled ps-0 mb-0 footerLinks">
                <li className="py-1">
                  <a
                    href="https://imsohungry.com/Customer/About"
                    className="text-white"
                  >
                    About
                  </a>
                </li>
                <li className="py-1">
                  <a href="https://imsohungry.com/faq" className="text-white">
                    Faq
                  </a>
                </li>
                <li className="py-1">
                  <a
                    href="https://imsohungry.com/privacy"
                    className="text-white"
                  >
                    Security & Privacy
                  </a>
                </li>
              </ul>
            </Grid>
            <Grid item md={4} xs={12} className="">
              <h4 className="footHead m-0 pb-2 text-white">Become a Partner</h4>
              <ul className="list-unstyled ps-0 mb-0 footerLinks">
                <li className="py-1">
                  <Link to="/register" className="text-white">
                    Restaurant SignUp
                  </Link>
                </li>
                <li className="py-1">
                  <a
                    href="https://imsohungry.com/driversignup"
                    className="text-white"
                  >
                    Driver SignUp
                  </a>
                </li>
                <li className="py-1">
                  <a href="https://imsohungry.com/blog" className="text-white">
                    Blog
                  </a>
                </li>
              </ul>
            </Grid>
            <Grid item md={4} xs={12} className="">
              <h4 className="footHead m-0 pb-2 text-white">Help & Contact</h4>
              <ul className="list-unstyled ps-0 mb-0 footerLinks">
                <li className="py-1">
                  <p className="text-white m-0">520-891-1700</p>
                </li>
                <li className="py-1">
                  {/* <p className="text-white m-0">support@imsohungry.com</p> */}
                  <p className="text-white m-0">support@tucsoneats.com</p>
                </li>
                <li className="py-1">
                  <p className="text-white m-0">
                    2813 E Broadway Blvd Tucson, AZ85716
                  </p>
                </li>
              </ul>
            </Grid>
            <Grid item md={12} className="copyright-area mt-4">
              <p className=" m-0 pb-2 text-center text-white">
                © 2022 IMSH | All rights reserved.
              </p>
            </Grid>
          </Grid>
        </Container>
      </footer>
    </>
  );
};
export default Footer;
