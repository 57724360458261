import React from "react";
import { Container, Grid } from "@material-ui/core";

const HowWeWork = () => {
  return (
    <>
      <section className="IconSec py-5">
        <Container fixed>
          <Grid container spacing={3}>
            <Grid item md={12} direction="column" alignItems="center">
              <div className="section-header mb-3">
                <h2 className="m-0 fw-bold">
                  How <span className="theme-clr ">Tucson Eats</span> <br />
                  works for <span className="theme-clr ">restaurant</span>{" "}
                  partners
                </h2>
              </div>
            </Grid>
            <Grid item md={4} xs={12} direction="column" alignItems="center">
              <div className="cstmCard">
                <div className="img-wrp mb-2">
                  <img
                    src="../images/ii1.png"
                    alt=""
                    className="img-fluid w-100"
                  />
                </div>
                <h4 className="m-0 pb-2">
                  Customers <span className="theme-clr">order</span>{" "}
                </h4>
                <p className="m-0">
                  A customer finds your restaurant and places an order through
                  the Tucson Eats app.
                </p>
              </div>
            </Grid>
            <Grid item md={4} xs={12} direction="column" alignItems="center">
              <div className="cstmCard">
                <div className="img-wrp mb-2">
                  <img
                    src="../images/ii2.png"
                    alt=""
                    className="img-fluid w-100"
                  />
                </div>
                <h4 className="m-0 pb-2">
                  You <span className="theme-clr">prepare</span>
                </h4>
                <p className="m-0">
                  Your restaurant accepts and prepares the order.{" "}
                </p>
              </div>
            </Grid>
            <Grid item md={4} xs={12} direction="column" alignItems="center">
              <div className="cstmCard">
                <div className="img-wrp mb-2">
                  <img
                    src="../images/ii3.png"
                    alt=""
                    className="img-fluid w-100"
                  />
                </div>
                <h4 className="m-0 pb-2">
                  Delivery partners <span className="theme-clr">arrive</span>
                </h4>
                <p className="m-0">
                  Delivery people using the tucsoneats platform pick up the
                  order from your restaurant, then deliver it to the customer.{" "}
                </p>
              </div>
            </Grid>
          </Grid>
        </Container>
      </section>
    </>
  );
};
export default HowWeWork;
