import React from "react";
import Select2 from "react-select";
import {
  toast,
  withRouter,
  Row,
  Col,
  Axios,
  COUNTRIES,
  TEXTFIELD,
  PASSWORDFIELD,
  SELECT,
  GoogleAutocomplete,
  SUBMIT,
  RESET,
  ApiUrl,
  checkValidations,
  checkError,
  FILEUPLOAD,
  Loading,
  CircularProgress,
  Input,
  InputLabel,
  MenuItem,
  FormControl,
  Select,
} from "../components";
import { PASS_REGEX } from "../ApiUrl";

const {
  EMAIL_REGEX,
  PASS_VALIDATION_MSG,
  Numeric,
  API_ADD_STORES,
  API_EDIT_STORES,
  API_GET_CATEGORY_URL,
} = ApiUrl;
const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};

class SignIn extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      editId: this.props.editId,
      process: this.props.role,
      imagePreviewUrl: "",
      details: this.props.details,
      loading: false,
      categoriesList: [],
      cuisinesList: [],
      name: "",
      vendorName: "",
      email: "",
      country: "",
      mobileNumber: "",
      city: "",
      address: "",
      lng: "",
      lat: "",
      cuisines: [],
      selectedOption: [],
      categories: [],
      images: {},
      businessproof: {},
      password: "",
      password2: "",
      imagePreviewUrl: "",
      errors: {
        name: "",
        email: "",
        vendorName: "",
        country: "",
        city: "",
        address: "",
        lng: "",
        lat: "",
        cuisines: "",

        images: this.props.role !== "edit" ? "Required" : "",
        businessproof: this.props.role !== "edit" ? "Required" : "",
      },
      disabled: true,
    };
  }

  componentDidMount() {
    const { editId, process } = this.state;
    this.setState({ loading: true });
    Axios.post(API_GET_CATEGORY_URL, {
      limit: 1000,
      order: 1,
      orderBy: "featuredPosition",
    })
      .then((res) => {
        let data = res.data.data;
        if (data.length > 0) {
          data = data.map((key) => {
            return {
              [key._id]: key.catName,
            };
          });
          this.setState({ categoriesList: data });
        } else {
          toast.error("Please first add categories.");
        }
      })
      .catch((err) => {
        //console.log("Server error");
      });

    this.setState({ loading: false });
  }

  handleChange = (event) => {
    event.preventDefault();
    const { name, value, validations } = event.target;
    let finalVal = value;
    let errors = this.state.errors;
    switch (name) {
      case "name":
        errors.name = value.length < 1 ? "Required" : "";
        break;
      case "vendorName":
        errors.vendorName = value.length < 1 ? "Required" : "";
        break;
      case "email": {
        let err = "";
        if (value.length < 1) {
          err = "Required";
        } else {
          if (!EMAIL_REGEX.test(value)) {
            err = "Invalid email";
          }
        }
        errors.email = err;
        break;
      }
      case "password": {
        let err = "";
        if (value.length < 1) {
          err = "Required";
        } else {
          if (!PASS_REGEX.test(value)) {
            err = "Invalid Password";
          }
        }
        errors.password = err;
        break;
      }
      case "password2": {
        let err = "";
        if (value.length < 1) {
          err = "Required";
        } else {
          if (this.state.password !== value) {
            err = "Confirm Password should be match to Password";
          }
        }
        errors.password2 = err;
        break;
      }
      case "mobileNumber": {
        let err = "";
        if (value.length < 1) {
          err = "Required";
        } else {
          if (!Numeric.test(value)) {
            err = "Invalid Mobile Number";
            // finalVal = "";
          }
        }
        errors.mobileNumber = err;

        break;
      }

      case "country":
        errors.country = value.length < 1 ? "Required" : "";
        break;
      case "city":
        errors.city = value.length < 1 ? "Required" : "";
        break;
      case "address":
        errors.address = value.length < 1 ? "Required" : "";
        break;
      case "categories":
        errors.categories = value.length < 1 ? "Required" : "";
        break;
      default:
        break;
    }

    this.setState({ errors, [name]: finalVal }, () => {
      let err = checkError(errors);
      this.setState({ disabled: err.disabled });
    });
  };

  handleFile = (event) => {
    event.preventDefault();
    const { errors } = this.state;
    let image = event.target.files[0];
    let newErr = { ...errors, [event.target.name]: "" };
    let reader = new FileReader();
    this.setState(
      {
        [event.target.name]: image,
        errors: newErr,
      },
      () => {
        let err = checkError(newErr);
        this.setState({ disabled: err.disabled });
      }
    );
  };
  redirectPage = () => {
    this.props.history.push("/");
  };
  handleAddress = (address) => {
    let errors = this.state.errors;
    let add = address;
    errors.address = address.address < 1 ? "Required" : "";
    add.errors = errors;
    this.setState(add);
  };

  handleSubmit = (event) => {
    event.preventDefault();
    this.setState({ loading: true, disabled: true });
    const {
      name,
      vendorName,
      email,
      mobileNumber,
      country,
      city,
      address,
      lng,
      lat,
      cuisines,
      categories,
      images,
      businessproof,
      password,
      password2,
      editId,
      process,
    } = this.state;

    let data = {
      name: name,
      vendorName: vendorName,
      email: email,
      mobileNumber: mobileNumber,
      address: address,
      city: city,
      country: country,
      lat: lat,
      lng: lng,
      password: password,
      password2: password2,
    };
    if (categories.length < 1) {
      toast.error("Please select Categories.");
    }
    let valid = checkValidations(data);
    if (valid.error) {
      toast.error(valid.error);
      this.setState({ loading: false, disabled: false });
      return false;
    }

    data = valid.data;

    if (images.name !== undefined > 0) {
      data.profileImage = images;
    }
    if (businessproof.name !== undefined > 0) {
      data.businessproof = businessproof;
    }
    data.categories = categories.join(",");
    let url = API_ADD_STORES;
    if (process == "edit") {
      data.storeid = editId;
      url = API_EDIT_STORES;
    }
    let formData = new FormData();
    for (var key in data) {
      formData.append(key, data[key]);
    }

    Axios.post(url, formData)
      .then((res) => {
        let response = res.data;
        const { status, message } = response;
        if (status === "failure") {
          toast.error(message);
          this.setState({ loading: false, disabled: false });
          return false;
        } else {
          toast.success(response.message);

          setTimeout(() => {
            this.redirectPage();
          }, 1000);
        }
      })
      .catch((error) => {
        let res = error.response;
        toast.error(res.data.message);
        this.setState({ loading: false, disabled: false });
      });
  };
  reset = () => {
    this.setState({
      imagePreviewUrl: "",
      name: "",
      vendorName: "",
      email: "",
      country: "",
      mobileNumber: "",
      city: "",
      address: "",
      lng: "",
      lat: "",
      cuisines: [],
      categories: [],
      images: {},
      businessproof: {},
      errors: {
        name: "",
        vendorName: "",
        email: "",
        country: "",
        city: "",
        address: "",
        lng: "",
        lat: "",
        cuisines: "",
        categories: "",
        images: this.props.role !== "edit" ? "Required" : "",
      },
      disabled: true,
    });
  };

  render() {
    const {
      categoriesList,
      cuisinesList,
      name,
      vendorName,
      email,
      imagePreviewUrl,
      mobileNumber,
      country,
      city,
      address,
      lng,
      lat,
      password,
      password2,
      cuisines,
      categories,
      images,
      businessproof,
      selectedOption,
      errors,
      disabled,
      loading,
    } = this.state;
    return (
      <div className="app-signup-content">
        {loading && (
          <div className="loader-view">
            <CircularProgress />
          </div>
        )}
        <form onSubmit={this.handleSubmit} noValidate>
          <Row>
            <Col md="12" xs="12">
              <Row>
                <Col md="6" xs="12">
                  <TEXTFIELD
                    label="Vendor Name"
                    value={vendorName}
                    name={"vendorName"}
                    onChange={this.handleChange}
                    errors={errors}
                  />
                </Col>
                <Col md="6" xs="12">
                  <TEXTFIELD
                    label="Store Name"
                    value={name}
                    name={"name"}
                    onChange={this.handleChange}
                    errors={errors}
                  />
                </Col>
                <Col md="6" xs="12">
                  <TEXTFIELD
                    label="Email"
                    value={email}
                    name={"email"}
                    onChange={this.handleChange}
                    errors={errors}
                  />
                </Col>
                <Col md="6" xs="12">
                  <TEXTFIELD
                    label="Mobile Number"
                    value={mobileNumber}
                    name={"mobileNumber"}
                    onChange={this.handleChange}
                    errors={errors}
                  />
                </Col>

                <Col md="6">
                  <GoogleAutocomplete
                    label="Address"
                    address={address}
                    lat={lat}
                    lng={lng}
                    errors={errors}
                    name="address"
                    onChange={this.handleAddress}
                  />
                </Col>
                <Col md="6" xs="12">
                  <TEXTFIELD
                    label="City"
                    value={city}
                    name={"city"}
                    onChange={this.handleChange}
                    errors={errors}
                  />
                </Col>
                <Col md="6" xs="12">
                  <SELECT
                    label="Country"
                    value={country}
                    name={"country"}
                    onChange={this.handleChange}
                    data={COUNTRIES}
                    errors={errors}
                  />
                </Col>
                <Col md="6" xs="12">
                  {/* {categories && 
                                     <Select2
                                        value={selectedOption}
                                        onChange={this.handleChange2}
                                        options={categories}
                                        isMulti
                                    />
                  } */}
                  <FormControl className="select-multi">
                    <InputLabel id="demo-mutiple-checkbox-label">
                      Select Cuisines
                    </InputLabel>
                    <Select
                      fullWidth
                      id="demo-mutiple-name"
                      multiple
                      value={categories}
                      name={"categories"}
                      onChange={this.handleChange}
                      input={<Input />}
                      MenuProps={MenuProps}
                    >
                      {categoriesList.map((keys, index) => {
                        for (let [key, value] of Object.entries(keys)) {
                          return <MenuItem value={key}>{value}</MenuItem>;
                        }
                      })}
                    </Select>
                  </FormControl>
                  {/* <SELECT
                    label="Categoty"
                    fullWidth
                    value={categories}
                    name={"categories"}
                    onChange={this.handleChange}
                    data={categoriesList}
                    errors={errors}
                  /> */}
                </Col>
                <Col md="6" xs="12">
                  <PASSWORDFIELD
                    label="Password"
                    value={password}
                    name={"password"}
                    onChange={this.handleChange}
                    note={PASS_VALIDATION_MSG}
                    errors={errors}
                  />
                </Col>
                <Col md="6" xs="12">
                  <PASSWORDFIELD
                    label="Confirm Password"
                    value={password2}
                    name={"password2"}
                    onChange={this.handleChange}
                    note={PASS_VALIDATION_MSG}
                    errors={errors}
                  />
                </Col>
                <Col md="6" xs="12">
                  <FILEUPLOAD
                    label="Image"
                    value={images}
                    name={"images"}
                    onChange={this.handleFile}
                    errors={errors}
                  />
                </Col>
                <Col md="6" xs="12">
                  <FILEUPLOAD
                    label="Business Proof"
                    value={businessproof}
                    name={"businessproof"}
                    onChange={this.handleFile}
                    errors={errors}
                  />
                </Col>
              </Row>
              <Row>
                <Col md="4" xs="12">
                  <SUBMIT title="Sign Up" disabled={disabled} />
                </Col>
                <Col md="4" xs="12">
                  <RESET onClick={this.reset} />
                </Col>
              </Row>
            </Col>
          </Row>
        </form>
      </div>
    );
  }
}

export default withRouter(SignIn);
