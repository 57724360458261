import React from "react";
import { Container, Grid } from "@material-ui/core";
import Typography from "@material-ui/core/Typography";
import { withStyles } from "@material-ui/core/styles";
import MuiAccordion from "@material-ui/core/Accordion";
import MuiAccordionSummary from "@material-ui/core/AccordionSummary";
import MuiAccordionDetails from "@material-ui/core/AccordionDetails";

const Faq = () => {
  const Accordion = withStyles({
    root: {
      border: "1px solid rgba(0, 0, 0, .125)",
      boxShadow: "none",
      "&:not(:last-child)": {
        borderBottom: 0,
      },
      "&:before": {
        display: "none",
      },
      "&$expanded": {
        margin: "auto",
      },
    },
    expanded: {},
  })(MuiAccordion);
  const AccordionSummary = withStyles({
    root: {
      backgroundColor: "rgba(0, 0, 0, .03)",
      borderBottom: "1px solid rgba(0, 0, 0, .125)",
      marginBottom: -1,
      minHeight: 56,
      "&$expanded": {
        minHeight: 56,
      },
    },
    content: {
      "&$expanded": {
        margin: "12px 0",
      },
    },
    expanded: {},
  })(MuiAccordionSummary);

  const AccordionDetails = withStyles((theme) => ({
    root: {
      padding: theme.spacing(2),
    },
  }))(MuiAccordionDetails);
  return (
    <>
      <section
        className="accordion py-5"
        // style={{ backgroundColor: "#fff8f2" }}
      >
        <Container fixed>
          <Grid
            container
            spacing={3}
            alignItems="center"
            justifyContent="space-between"
          >
            <Grid item md={10} className="mx-auto">
              <div className="section-header mb-3">
                <h2 className="m-0 fw-bold">
                  Questions? We've got{" "}
                  <span className="theme-clr">answers.</span>
                </h2>
              </div>
            </Grid>
            <Grid item md={10} className="mx-auto">
              <Accordion
                square
                className="border-0 my-2"
                // expanded={expanded === "panel1"}
                // onChange={handleChange("panel1")}
              >
                <AccordionSummary
                  className="bg-transparent border-0"
                  aria-controls="panel1d-content"
                  id="panel1d-header"
                >
                  <Typography>
                    How long does it take to become a partner?
                  </Typography>
                </AccordionSummary>
                <AccordionDetails>
                  <Typography>
                    Depending on how many locations you have, it’s possible to
                    become an Tucson Eats restaurant partner and start accepting
                    orders in just a few days! You can begin the process by
                    signing up here. We’re excited to hear from you!
                  </Typography>
                </AccordionDetails>
              </Accordion>
              <Accordion
                square
                className="border-0 my-2"

                // expanded={expanded === "panel2"}
                // onChange={handleChange("panel2")}
              >
                <AccordionSummary
                  className="bg-transparent border-0"
                  aria-controls="panel2d-content"
                  id="panel2d-header"
                >
                  <Typography>Who handles each delivery?</Typography>
                </AccordionSummary>
                <AccordionDetails>
                  <Typography>
                    The tucsoneats platform can connect you with independent
                    drivers, bike and scooter riders, and walkers who deliver to
                    your customers. Because of the network of delivery people
                    using the tucsoneats platform, restaurants don’t have to
                    keep their own delivery staff. But if you do have your own
                    staff, we’re flexible—you can use them too. Reach out to
                    restaurants@tucsoneats.com or directly to your Tucson Eats
                    contact to see if this option is now available in your city.
                  </Typography>
                </AccordionDetails>
              </Accordion>
              <Accordion
                square
                className="border-0 my-2"

                // expanded={expanded === "panel3"}
                // onChange={handleChange("panel3")}
              >
                <AccordionSummary
                  className="bg-transparent border-0"
                  aria-controls="panel3d-content"
                  id="panel3d-header"
                >
                  <Typography>How does pricing work?</Typography>
                </AccordionSummary>
                <AccordionDetails>
                  <Typography>
                    Tucson Eats pricing has two parts. A one-time activation fee
                    sets restaurants up with a welcome kit, tablet, restaurant
                    software, and professional photo shoot. A service fee is
                    calculated as a percentage of each restaurant order made
                    through Tucson Eats. Want more details? Email
                    restaurants@tucsoneats.com and we’ll get back to you.
                  </Typography>
                </AccordionDetails>
              </Accordion>
            </Grid>
          </Grid>
        </Container>
      </section>
    </>
  );
};
export default Faq;
