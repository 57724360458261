import React, { Component } from "react";
import { NavLink, withRouter } from "react-router-dom";
import Button from "@material-ui/core/Button";
import CustomScrollbars from "util/CustomScrollbars";

class SidenavContent extends Component {
  componentDidMount() {
    const { history } = this.props;
    const that = this;
    const pathname = `#${history.location.pathname}`; // get current path

    const subMenuLi = document.querySelectorAll(".sub-menu > li");
    for (let i = 0; i < subMenuLi.length; i++) {
      subMenuLi[i].onclick = function(event) {
        event.stopPropagation();
      };
    }

    const menuLi = document.getElementsByClassName("menu");
    for (let i = 0; i < menuLi.length; i++) {
      menuLi[i].onclick = function(event) {
        for (let j = 0; j < menuLi.length; j++) {
          const parentLi = that.closest(this, "li");
          if (
            menuLi[j] !== this &&
            (parentLi === null || !parentLi.classList.contains("open"))
          ) {
            menuLi[j].classList.remove("open");
          }
        }
        this.classList.toggle("open");
        event.stopPropagation();
      };
    }

    const activeLi = document.querySelector('a[href="' + pathname + '"]'); // select current a element
    try {
      const activeNav = this.closest(activeLi, "ul"); // select closest ul
      if (activeNav.classList.contains("sub-menu")) {
        this.closest(activeNav, "li").classList.add("open");
      } else {
        this.closest(activeLi, "li").classList.add("open");
      }
    } catch (error) {}
  }

  closest(el, selector) {
    try {
      let matchesFn;
      // find vendor prefix
      [
        "matches",
        "webkitMatchesSelector",
        "mozMatchesSelector",
        "msMatchesSelector",
        "oMatchesSelector",
      ].some(function(fn) {
        if (typeof document.body[fn] == "function") {
          matchesFn = fn;
          return true;
        }
        return false;
      });

      let parent;

      // traverse parents
      while (el) {
        parent = el.parentElement;
        if (parent && parent[matchesFn](selector)) {
          return parent;
        }
        el = parent;
      }
    } catch (e) {}

    return null;
  }

  render() {
    return (
      <CustomScrollbars className=" scrollbar">
        <ul className="nav-menu">
          <li>
            <NavLink to="/app/dashboard/index">
              <i className="zmdi zmdi-view-dashboard zmdi-hc-fw" />
              <span className="nav-text">Dashboard</span>
            </NavLink>
          </li>
          <li className="menu collapse-box">
            <Button href="javascript:void(0)" className="cbutton">
              <i className="zmdi zmdi-collection-item zmdi-hc-fw" />
              <span className="nav-text">Menu Items </span>
            </Button>
            <ul className="sub-menu">
              <li>
                <NavLink className="prepend-icon cbutton" to="/app/items/list">
                  <i className="zmdi zmdi-view-list" />
                  <span className="nav-text">All</span>
                </NavLink>
              </li>
              <li>
                <NavLink className="prepend-icon cbutton" to="/app/items/add">
                  <i class="zmdi zmdi-plus-circle"></i>

                  <span className="nav-text">Add</span>
                </NavLink>
              </li>
              <li>
                <NavLink className="prepend-icon cbutton" to="/app/menuview">
                  <i class="zmdi zmdi-swap"></i>

                  <span className="nav-text">Menu Item</span>
                </NavLink>
              </li>

              <li>
                <NavLink
                  className="prepend-icon cbutton"
                  to="/app/items/import"
                >
                  <i class="zmdi zmdi-cloud-upload"></i>

                  <span className="nav-text">CSV import</span>
                </NavLink>
              </li>
            </ul>
          </li>

          <li className="menu collapse-box">
            <Button href="javascript:void(0)" className="cbutton">
              <i className="zmdi zmdi-collection-item zmdi-hc-fw" />
              <span className="nav-text">Fresh Market</span>
            </Button>
            <ul className="sub-menu">
              <li>
                <NavLink
                  className="prepend-icon cbutton"
                  to="/app/freshmarket/list"
                >
                  <i className="zmdi zmdi-view-list" />
                  <span className="nav-text">All</span>
                </NavLink>
              </li>
              <li>
                <NavLink
                  className="prepend-icon cbutton"
                  to="/app/freshmarket/add"
                >
                  <i class="zmdi zmdi-plus-circle"></i>

                  <span className="nav-text">Add</span>
                </NavLink>
              </li>

              {/*<li>
                <NavLink
                  className="prepend-icon cbutton"
                  to="/app/freshmarket/import"
                >
                  <i class="zmdi zmdi-cloud-upload"></i>

                  <span className="nav-text">CSV import</span>
                </NavLink>
    </li>*/}
            </ul>
          </li>
          <li className="menu collapse-box">
            <Button href="javascript:void(0)" className="cbutton">
              <i className="zmdi zmdi-dot-circle  zmdi-hc-fw" />
              <span className="nav-text">Orders</span>
            </Button>
            <ul className="sub-menu">
              <li>
                <NavLink className="prepend-icon cbutton" to="/app/orders/all">
                  <i className="zmdi zmdi-view-list" />
                  <span className="nav-text">All</span>
                </NavLink>
              </li>
              <li>
                <NavLink className="prepend-icon cbutton" to="/app/orders/new">
                  <i class="zmdi zmdi-circle"></i>

                  <span className="nav-text">New</span>
                </NavLink>
              </li>

              <li>
                <NavLink
                  className="prepend-icon cbutton"
                  to="/app/orders/completed"
                >
                  <i class="zmdi zmdi-check-circle"></i>

                  <span className="nav-text">Completed</span>
                </NavLink>
              </li>
            </ul>
          </li>

          <li>
            <NavLink to="/app/payments">
              <i className="zmdi zmdi-money zmdi-hc-fw" />
              <span className="nav-text">Transactions </span>
            </NavLink>
          </li>

          <li className="menu collapse-box">
            <Button href="javascript:void(0)" className="cbutton">
              <i className="zmdi zmdi-settings zmdi-hc-fw" />
              <span className="nav-text">Settings</span>
            </Button>
            <ul className="sub-menu">
              <li>
                <NavLink
                  className="prepend-icon cbutton"
                  to="/app/settings/profile/edit"
                >
                  <i className="zmdi zmdi-account-circle" />
                  <span className="nav-text">Profile</span>
                </NavLink>
              </li>
              <li>
                <NavLink
                  className="prepend-icon cbutton"
                  to="/app/settings/password-update"
                >
                  <i class="zmdi zmdi-album"></i>

                  <span className="nav-text">Update Password</span>
                </NavLink>
              </li>
              <li>
                <NavLink
                  className="prepend-icon cbutton"
                  to="/app/settings/make-toprated"
                >
                  <i className="zmdi zmdi-dot-circle" />
                  <span className="nav-text">Make Top Rated</span>
                </NavLink>
              </li>
            </ul>
          </li>
        </ul>
      </CustomScrollbars>
    );
  }
}

export default withRouter(SidenavContent);
