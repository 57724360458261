import React from "react";
import Avatar from "@material-ui/core/Avatar";
import { connect } from "react-redux";
import Menu from "@material-ui/core/Menu";
import { NavLink, withRouter } from "react-router-dom";
import MenuItem from "@material-ui/core/MenuItem";
import { userSignOut } from "actions/Auth";
import IntlMessages from "util/IntlMessages";
import Chip from "@material-ui/core/Chip";
import AccountCircleIcon from "@material-ui/icons/AccountCircle";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";
import Button from "@material-ui/core/Button";

class UserInfo extends React.Component {
  state = {
    anchorEl: null,
    open: false,
    openLogout:false
  };

  handleClick = event => {
    this.setState({ open: true, anchorEl: event.currentTarget });
  };

  handleRequestClose = () => {
    this.setState({ open: false });
  };

  logoutConfirmation =(state) =>{
    this.setState({openLogout:state})
  }
  render() {
    const{openLogout}=this.state;
    return (
      <div className="user-profile d-flex flex-row align-items-center">
      <Dialog
        open={openLogout}
        onClose={() => this.logoutConfirmation(false)}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">Logout Confirmation</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
           Do you want to Logout? Choose "Disagree" to prevent the action, Choose "Agree" to take action.
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => this.logoutConfirmation(false)} color="primary">
            Disagree
          </Button>
          <Button onClick={()=>this.props.userSignOut()} color="primary" autoFocus>
            Agree
          </Button>
        </DialogActions>
      </Dialog>
        <div className="user-detail" onClick={this.handleClick}>
          <Chip
            icon={<AccountCircleIcon />}
            label={localStorage.getItem("name")}
          />
          <i class="zmdi zmdi-caret-down zmdi-hc-fw align-middle"></i>
        </div>
        <Menu
          className="user-info"
          id="simple-menu"
          anchorEl={this.state.anchorEl}
          open={this.state.open}
          onClose={this.handleRequestClose}
          PaperProps={{
            style: {
              minWidth: 120,
              paddingTop: 0,
              paddingBottom: 0
            }
          }}
        >
          <MenuItem
            onClick={() => {
              this.handleRequestClose();
            }}
          >
            <NavLink to="/app/settings/password-update">
              <i class="zmdi zmdi-key zmdi-hc-fw mr-2"></i>
              <IntlMessages id="Password" />
            </NavLink>
          </MenuItem>
          <MenuItem
            onClick={() => {
              this.handleRequestClose();
              this.logoutConfirmation(true);
              //this.props.userSignOut();
            }}
          >
            <i className="zmdi zmdi-sign-in zmdi-hc-fw mr-2" />

            <IntlMessages id="popup.logout" />
          </MenuItem>
        </Menu>
      </div>
    );
  }
}

const mapStateToProps = ({ settings }) => {
  const { locale } = settings;
  return { locale };
};
export default connect(mapStateToProps, { userSignOut })(UserInfo);
