import React from "react";
import Avatar from "@material-ui/core/Avatar";
import CssBaseline from "@material-ui/core/CssBaseline";
import Link from "@material-ui/core/Link";
import Paper from "@material-ui/core/Paper";
import Grid from "@material-ui/core/Grid";
import LockOutlinedIcon from "@material-ui/icons/LockOutlined";
import Typography from "@material-ui/core/Typography";
import { withStyles } from "@material-ui/core/styles";

import {
  withRouter,
  connect,
  TEXTFIELD,
  ToastContainer,
  toast,
  Axios,
  PASSWORDFIELD,
  SUBMIT,
  RESET,
  SELECT,
  GoogleAutocomplete,
  CircularProgress,
  Logo,
  checkValidations,
  checkError,
  FILEUPLOAD,
  Row,
  Col,
  ApiUrl,
  Input,
  InputLabel,
  MenuItem,
  FormControl,
  Select,
  COUNTRIES,
  PHONECODES,
} from "../../components";

import {
  hideMessage,
  showAuthLoader,
  userSignInSuccess,
  hideAuthLoader,
} from "actions/Auth";
import { Container, GridList } from "@material-ui/core";
import WhyOurBrand from "../../components/auth/authBody/WhyOurBrand/Index";
import ImgWithText from "../../components/auth/authBody/ImageWithText/Index";
import HowWeWork from "../../components/auth/authBody/HowWeWork/Index";
import OurClient from "../../components/auth/authBody/OurClient/Index";
import GetStartedSteps from "../../components/auth/authBody/GetStartedSteps/Index";
import Faq from "../../components/auth/authBody/Faq/Index";
import Header from "../../components/auth/authBody/Header";
import Footer from "../../components/auth/authBody/Footer/Index";
import { Button } from "react-bootstrap";

const {
  EMAIL_REGEX,
  PASS_REGEX,
  PASS_VALIDATION_MSG,
  Numeric,
  API_ADD_STORES,
  API_EDIT_STORES,
  API_GET_CATEGORY_URL,
  API_GET_FRESHMARKET_CATEGORY_URL,
  API_GET_MENU_BY_CATEGORY,
} = ApiUrl;
const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};
const useStyles = (theme) => ({
  root: {
    height: "100vh",
  },
  image: {
    backgroundImage: "url(https://source.unsplash.com/random)",
    backgroundRepeat: "no-repeat",
    backgroundColor:
      theme.palette.type === "light"
        ? theme.palette.grey[50]
        : theme.palette.grey[900],
    backgroundSize: "cover",
    backgroundPosition: "center",
  },
  paper: {
    margin: theme.spacing(8, 4),
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
  },
  avatar: {
    margin: theme.spacing(1),
    backgroundColor: theme.palette.secondary.main,
  },
  form: {
    width: "100%", // Fix IE 11 issue.
    marginTop: theme.spacing(1),
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
  },
});

class SignUp extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      step: "1",
      editId: this.props.editId,
      process: this.props.role,
      name: "",
      email: "",
      password: "",
      mobileNumber: "",
      menuItemRef: [],
      country: "",
      secondaryEmail: "",
      city: "",
      state: "",
      managerName: "",
      managerMobile: "",
      managerNameAlt: "",
      managerMobilAlt: "",
      pricePerPerson: "",
      // managerList: [{ ...managerListObj }],
      // contactList: [{ ...contactListObj }],
      address: "",
      lat: "",
      lng: "",
      categoryList: [],
      menuList: [],
      categoryIdRef: [],
      countryCode: "",
      // vendorName: "",
      url: "",
      serviceType: "",
      restaurantType: "",
      openingTime: "",
      closingTime: "",
      profileImage: {},
      coverImage: {},
      namesOption: {
        Restaurant: "Market",
        Manager: "Seller",
      },
      errors: {
        name: "",
        email: "",
        password: "",
        mobileNumber: "",
        country: "",
        city: "",
        state: "",
        pricePerPerson: "",
        // categoryId: "",
        menuItemRef: "",
        managerMobile: "",
        categoryList: [],
        address: "",
        lat: "",
        lng: "",
        managerName: "",
        countryCode: "",
        menuList: "",
        // vendorName: "",
        url: "",
        serviceType: "",
        restaurantType: "",
        openingTime: "",
        closingTime: "",
        profileImage: "Required",
        coverImage: "Required",
      },
      disabled: true,
    };
  }

  componentDidMount() {
    const { editId, process, step } = this.state;

    console.log(step, "this.props.role");

    Axios.post(API_GET_CATEGORY_URL, { type: "all" })
      .then((res) => {
        const { status, data } = res.data;
        const categories = data.map((c) => {
          return { [c._id]: c.catName };
        });
        // console.log(categories, "categories");
        this.setState({
          categoryList: categories,
        });
      })
      .catch((err) => {
        console.log(err, 66565);
      });
  }

  handleCategory(val) {
    const url =
      val == "freshMarket"
        ? API_GET_FRESHMARKET_CATEGORY_URL
        : API_GET_CATEGORY_URL;
    Axios.post(url, { type: "all" })
      .then((res) => {
        const { status, data } = res.data;
        const categories = data.map((c) => {
          return { [c._id]: c.catName };
        });
        console.log(categories, "categories");
        this.setState({
          categoryList: categories,
        });
      })
      .catch((err) => {
        console.log(err, 66565);
      });
  }
  handleChange = (event) => {
    console.log("murda ", event);
    event.preventDefault();
    const { name, value, validations } = event.target;
    let errors = this.state.errors;
    let finalVal = value;

    console.log(finalVal, name, 123654);

    switch (name) {
      case "name":
        errors.name = value.length < 1 ? "Required" : "";
        break;
      case "address":
        errors.address = value.length < 1 ? "Required" : "";
        break;
      // case "managerName":
      //   errors.managerName = value.length < 1 ? "Required" : "";
      //   break;
      // case "managerMobile":
      //   errors.managerMobile = value.length < 1 ? "Required" : "";
      //   break;
      case "categoryIdRef": {
        errors.categoryIdRef = value.length < 1 ? "" : "";
        if (value.length > 0) {
          this.handelCategoryById(value);
        }
        break;
      }
      // case "email": {
      //   let err = "";
      //   if (value.length < 1) {
      //     err = "Required";
      //   } else {
      //     if (!EMAIL_REGEX.test(value)) {
      //       err = "Invalid email";
      //     }
      //   }
      //   errors.email = err;
      //   break;
      // }
      // case "secondaryEmail": {
      //   let err = "";
      //   if (value.length < 1) {
      //     err = "Required";
      //   } else {
      //     if (!EMAIL_REGEX.test(value)) {
      //       err = "Invalid email";
      //     }
      //   }
      //   errors.secondaryEmail = err;
      //   break;
      // }
      case "mobileNumber": {
        let err = "";
        if (value.length < 10) {
          err = "Minimum 10 digits are required";
        } else {
          if (!Numeric.test(value)) {
            err = "Only numeric allowed";
            finalVal = "";
          }
          if (value.length > 10) {
            err = "More than 10 digits are not allowed";
            finalVal = "";
          }
        }
        errors.mobileNumber = err;

        break;
      }
      // case "managerMobilAlt": {
      //   let err = "";
      //   if (value.length < 6) {
      //     err = "Minimum 6 digits are required";
      //   } else {
      //     if (!Numeric.test(value)) {
      //       err = "Only numeric allowed";
      //       finalVal = "";
      //     }
      //     if (value.length > 10) {
      //       err = "More than 10 digits are not allowed";
      //       finalVal = "";
      //     }
      //   }
      //   errors.managerMobilAlt = err;

      //   break;
      // }
      case "password": {
        let err = "";
        if (value.length < 1) {
          err = "Required";
        } else {
          if (!PASS_REGEX.test(value)) {
            err = "Invalid format";
          }
        }
        errors.password = err;

        break;
      }
      default:
        break;
    }
    if (name == "restaurantType") {
      this.handleCategory(finalVal);
    }

    this.setState({ errors, [name]: finalVal }, () => {
      let err = checkError(errors);
      this.setState({ disabled: err.disabled });
    });
  };
  handleAddress = (address) => {
    let errors = { ...this.state.errors };
    const data = {
      address: address.address,
      postCode: address.postal_code,
      city: address.city,
      state: address.state,
      lat: address.lat,
      lng: address.lng,
    };

    errors.address = address.address < 1 ? "Required" : "";

    errors.city = address.city < 1 ? "Required" : "";

    errors.state = address.state < 1 ? "Required" : "";

    this.setState({ ...data, errors });
  };
  redirectPage = () => {
    this.props.history.push("/");
  };
  handleFile = (event) => {
    event.preventDefault();
    const { errors } = this.state;
    const { name, value } = event.target;
    let file = event.target.files[0];
    let newErr = { ...errors, [name]: "" };
    let reader = new FileReader();

    reader.onloadend = () => {
      this.setState(
        {
          [name]: file,
          errors: newErr,
          imagePreviewUrl: reader.result,
        },
        () => {
          let err = checkError(newErr);
          this.setState({ disabled: err.disabled });
        }
      );
    };
    reader.readAsDataURL(file);
  };
  handelCategoryById = (id) => {
    Axios.post(API_GET_MENU_BY_CATEGORY, { categoryId: id })
      .then((res) => {
        console.log(res, "mydata");
        const { status, data } = res.data;
        const menuList = data.map((c) => {
          return { [c._id]: c.menuItemName };
        });
        this.setState({
          menuList: menuList,
        });
        console.log(this.state.menuList, "List");
      })
      .catch((err) => {
        console.log(err, 66565);
      });
  };
  handleLabelName = (name) => {
    if (this.state.restaurantType == "freshMarket") {
      return this.state?.namesOption[name];
    }
    return name;
  };
  handleSubmit = (event) => {
    event.preventDefault();

    this.setState({ loading: true, disabled: true });
    const {
      name,
      email,
      password,
      mobileNumber,
      country,
      secondaryEmail,
      city,
      state,
      menuList,
      menuItemRef,
      pricePerPerson,
      address,
      lat,
      managerList,
      contactList,
      managerNameAlt,
      managerMobilAlt,
      managerMobile,
      lng,
      countryCode,
      categoryIdRef,
      // vendorName,
      managerName,
      url,
      serviceType,
      restaurantType,
      openingTime,
      closingTime,
      profileImage,
      coverImage,
      editId,
      process,
      errors,
    } = this.state;
    let err = checkError(errors);
    if (err.disabled === true) {
      toast.error(err.error);
      this.setState({ loading: false, disabled: false });
      return false;
    }

    let data = {
      name,
      // email,
      mobileNumber,
      country: "usa",
      city,
      state,
      address,
      lat,
      // managerNameAlt,
      // managerMobilAlt,
      // managerMobile,
      lng,
      // secondaryEmail,
      countryCode,
      // vendorName,
      // menuItemRef,
      // categoryIdRef,
      // serviceType,
      // managerName,
      restaurantType,
      // openingTime,
      // closingTime,
    };
    if (password) {
      data.password = password;
    }
    if (email) {
      data.email = email;
    }
    if (url) {
      data.url = url;
    }
    if (managerNameAlt) {
      data.managerNameAlt = managerNameAlt;
    }
    if (managerMobilAlt) {
      data.managerMobilAlt = managerMobilAlt;
    }
    if (managerMobile) {
      data.managerMobile = managerMobile;
    }
    if (secondaryEmail) {
      data.secondaryEmail = secondaryEmail;
    }
    if (categoryIdRef) {
      data.categoryIdRef = categoryIdRef;
    } else {
      data.categoryIdRef = "";
    }
    if (serviceType) {
      data.serviceType = serviceType;
    }
    if (managerName) {
      data.managerName = managerName;
    }
    if (openingTime) {
      data.openingTime = openingTime;
    }
    if (closingTime) {
      data.closingTime = closingTime;
    }

    let valid = checkValidations(data);
    if (valid.error) {
      toast.error(valid.error);
      this.setState({ loading: false, disabled: false });
      return false;
    }

    data = valid.data;

    if (profileImage.name !== undefined) {
      data.profileImage = profileImage;
    }
    if (coverImage.name !== undefined) {
      data.coverImage = coverImage;
    }

    if (restaurantType !== "freshMarket") {
      data.menuItemRef = menuItemRef;
    }

    let formData = new FormData();
    for (var key in data) {
      formData.append(key, data[key]);
    }

    Axios.post(API_ADD_STORES, formData)
      .then((res) => {
        let response = res.data;
        const { status, message } = response;
        if (status === "failure") {
          toast.error(message);
          this.setState({ loading: false, disabled: false });
          return false;
        } else {
          toast.success(response.message);

          setTimeout(() => {
            this.redirectPage();
          }, 1000);
        }
      })
      .catch((error) => {
        let res = error.response;
        toast.error(res.data.message);
        this.setState({ loading: false, disabled: false });
      });
  };
  reset = () => {
    this.setState({
      name: "",
      email: "",
      password: "",
      mobileNumber: "",
      country: "",
      city: "",
      state: "",
      pricePerPerson: "",
      address: "",
      lat: "",
      managerName: "",
      lng: "",
      countryCode: "",
      // vendorName: "",
      url: "",
      managerNameAlt: "",
      managerMobilAlt: "",
      managerMobile: "",
      serviceType: "",
      restaurantType: "",
      openingTime: "",
      closingTime: "",
      profileImage: {},
      namesOption: {
        Restaurant: "Fresh Market",
        Manager: "Seller",
      },
      errors: {
        name: "",
        email: "",
        password: "",
        mobileNumber: "",
        managerName: "",
        country: "",
        city: "",
        state: "",
        managerNameAlt: "",
        managerMobilAlt: "",
        managerMobile: "",
        pricePerPerson: "",
        address: "",
        lat: "",
        lng: "",
        countryCode: "",
        // vendorName: "",
        url: "",
        serviceType: "",
        restaurantType: "",
        openingTime: "",
        closingTime: "",
        profileImage: this.props.role === "add" ? "Required" : "",
        coverImage: this.props.role === "add" ? "Required" : "",
      },
      disabled: true,
    });
  };

  render() {
    const {
      errors,
      name,
      email,
      menuList,
      secondaryEmail,
      password,
      mobileNumber,
      address,
      city,
      state,
      contactList,
      categoryList,
      categoryIdRef,
      country,
      pricePerPerson,
      managerList,
      lat,
      lng,
      managerNameAlt,
      managerMobilAlt,
      managerMobile,
      countryCode,
      // vendorName,
      url,
      managerName,
      serviceType,
      restaurantType,
      openingTime,
      closingTime,
      profileImage,
      coverImage,
      process,
      menuItemRef,
      disabled,
      loading,
    } = this.state;
    const { classes } = this.props;
    return (
      <Grid component="main">
        <ToastContainer />

        <Header />
        <section
          container
          className="py-5 MainAccountForm position-relative d-flex align-items-center justify-content-center"
          style={{
            backgroundImage: 'url("../images/accountbg1.png")',
            backgroundSize: "cover",
            backgroundPosition: "center",
            minHeight: "100vh",
            zIndex: "9",
          }}
        >
          <Container fixed>
            <Grid container>
              ￼
              <Grid
                item
                md={4}
                container
                justify="center"
                spacing={0}
                direction="column"
                alignItems="center"
              >
                {/* <img
                    src={require("assets/images/logo1.png")}
                    className="logo-login"
                  /> */}
                <div className="bannerContent">
                  <h2 className="text-white m-0 pb-3">
                    Unlock a <span className="theme-clr">new revenue </span>
                    stream
                  </h2>
                  <p className="text-white">
                    tucsoneats global platform gives you the flexibility,
                    visibility and customer insights you need to connect with
                    more customers. Partner with us today.
                  </p>
                </div>
              </Grid>
              <Grid
                item
                md={7}
                // component={Paper}
                elevation={6}
                className="ml-auto"
                square
                alignContent="center"
              >
                <div
                  className="accountForm bg-white"
                  // style={{ maxHeight: "100vh", overflow: "scroll" }}
                >
                  {/* <div className={classes.paper}> */}
                  {/* <Avatar className={classes.avatar}>
                      <LockOutlinedIcon />
                    </Avatar> */}
                  <Typography className="form-head" component="h1" variant="h5">
                    Sign Up
                  </Typography>

                  <form onSubmit={this.handleSubmit} noValidate>
                    {this.state.step == "1" ? (
                      <>
                        <div className="py-2">
                          <div className="formCard border rounded p-3 px-lg-4">
                            <div className="top text-center mb-4 pb-3 border-bottom">
                              <h4 className="m-0">Restaurant Information</h4>
                            </div>
                            <Row>
                              <Col md="6" xs="12">
                                <div className="cstmSelect">
                                  <SELECT
                                    label="Type"
                                    value={restaurantType}
                                    name={"restaurantType"}
                                    onChange={this.handleChange}
                                    // errors={errors}
                                    variant="outlined"
                                    data={[
                                      { restaurant: "Restaurant" },
                                      { chef: "Chef" },
                                      { foodtruck: "Food Truck" },
                                      { freshMarket: "Fresh Market" },
                                    ]}
                                  />
                                </div>
                              </Col>
                              <Col md="6" xs="12">
                                <TEXTFIELD
                                  label={
                                    this.handleLabelName("Restaurant") +
                                    " Name *"
                                  }
                                  value={name}
                                  name={"name"}
                                  onChange={this.handleChange}
                                  variant="outlined"
                                  errors={errors}
                                />
                              </Col>
                              {/* <Col md="3" xs="12">
                  <TEXTFIELD
                    label="Vendor Name"
                    value={vendorName}
                    name={"vendorName"}
                    onChange={this.handleChange}
                    errors={errors}
                  />
                </Col> */}
                              <Col md="6" xs="12">
                                <TEXTFIELD
                                  label="Email"
                                  value={email}
                                  name="email"
                                  onChange={this.handleChange}
                                  // errors={errors}
                                  variant="outlined"
                                />
                              </Col>
                              <Col md="6" xs="12">
                                <TEXTFIELD
                                  label="Alt Email"
                                  value={secondaryEmail}
                                  name="secondaryEmail"
                                  onChange={this.handleChange}
                                  // errors={errors}
                                  variant="outlined"
                                />
                              </Col>
                              <Col md="6" xs="12">
                                <div className="cstmSelect">
                                  <SELECT
                                    label="Country Code"
                                    value={countryCode}
                                    name={"countryCode"}
                                    onChange={this.handleChange}
                                    data={PHONECODES}
                                    errors={errors}
                                  />
                                </div>
                              </Col>
                              <Col md="6" xs="12">
                                <TEXTFIELD
                                  label={
                                    this.handleLabelName("Restaurant") +
                                    " Phone Number *"
                                  }
                                  value={mobileNumber}
                                  name={"mobileNumber"}
                                  onChange={this.handleChange}
                                  errors={errors}
                                  variant="outlined"
                                />
                              </Col>
                              <Col md="6" xs="12">
                                <TEXTFIELD
                                  label="Url"
                                  value={url}
                                  name={"url"}
                                  onChange={this.handleChange}
                                  // errors={errors}
                                  variant="outlined"
                                />
                              </Col>
                              {/* <Col md="3" xs="12">
                  <SELECT
                    label="C Code"
                    value={countryCode}
                    name={"countryCode"}
                    onChange={this.handleChange}
                    data={PHONECODES}
                    errors={errors}
                  />
                </Col> */}

                              {/* <Col md="3" xs="12">
              <TEXTFIELD
                label="Manager Name"
                value={mobileNumber}
                name={"mobileNumber"}
                onChange={this.handleChange}
                errors={errors}
              />
            </Col> */}

                              <Col md="6" xs="12">
                                <div className="cstmSelect">
                                  <SELECT
                                    label="Service Type"
                                    value={serviceType}
                                    name={"serviceType"}
                                    onChange={this.handleChange}
                                    // errors={errors}
                                    data={[
                                      { pickUp: "Pick Up" },
                                      { delivery: "Delivery" },
                                      { both: "Both" },
                                    ]}
                                  />
                                </div>
                              </Col>

                              {/* <Col md="3"  xs="12">
              <TEXTFIELD
                label="Price Per Person"
                value={pricePerPerson}
                name={"pricePerPerson"}
                onChange={this.handleChange}
                errors={errors}
              />
            </Col> */}
                              {/* <Col md="3" xs="12">
                  <TEXTFIELD
                    label="Latitude"
                    value={lat}
                    name={"lat"}
                    onChange={this.handleChange}
                    errors={errors}
                  />
                </Col>
                <Col md="3" xs="12">
                  <TEXTFIELD
                    label="Longitude"
                    value={lng}
                    name={"lng"}
                    onChange={this.handleChange}
                    errors={errors}
                  />
                </Col> */}
                              {/* <Col md="6" xs="12"> */}
                              {/* <TEXTFIELD
                                  type="time"
                                  label="Open Hours"
                                  value={openingTime}
                                  name={"openingTime"}
                                  onChange={this.handleChange}
                                  // errors={errors}
                                  InputLabelProps={{
                                    shrink: true,
                                  }}
                                  variant="outlined"

                                  //onKeyDown={(e) =>Open Time e.preventDefault()}
                                /> */}
                              {/* </Col> */}
                              {/* <Col md="6" xs="12"> */}
                              {/* <TEXTFIELD
                                  type="time"
                                  label="Close Hours"
                                  value={closingTime}
                                  name={"closingTime"}
                                  onChange={this.handleChange}
                                  // errors={errors}
                                  InputLabelProps={{
                                    shrink: true,
                                  }}
                                  variant="outlined"

                                  //onKeyDown={(e) => e.preventDefault()}
                                /> */}
                              {/* </Col> */}

                              <Col md="6" xs="12">
                                <PASSWORDFIELD
                                  label="Password *"
                                  value={password}
                                  name={"password"}
                                  edit={process == "edit" ? true : false}
                                  onChange={this.handleChange}
                                  note={PASS_VALIDATION_MSG}
                                  variant="outlined"
                                  errors={errors}
                                />
                              </Col>
                              <Col md="6" xs="12" className="pt ">
                                {/* <FILEUPLOAD
                                  className="m-0"
                                  label="Profile Image *"
                                  value={profileImage}
                                  name={"profileImage"}
                                  onChange={this.handleFile}
                                  errors={errors}
                                /> */}
                              </Col>
                              <Col md="6" xs="12" className="pt ">
                                <FILEUPLOAD
                                  className="m-0"
                                  label="Cover Image *"
                                  value={coverImage}
                                  name={"coverImage"}
                                  onChange={this.handleFile}
                                  errors={errors}
                                />
                              </Col>
                              <Col md="6" xs="12" className="pt ">
                                <FILEUPLOAD
                                  className="m-0"
                                  label="Profile Image *"
                                  value={profileImage}
                                  name={"profileImage"}
                                  onChange={this.handleFile}
                                  errors={errors}
                                />
                              </Col>
                            </Row>
                          </div>
                        </div>
                        <Row className={"btnWrpper mt-3"}>
                          <Col md="12" xs="12">
                            <Button
                              onClick={() => this.setState({ step: "2" })}
                              className="common-btn border-0 w-100 d-flex align-items-center justify-content-center"
                            >
                              Next
                            </Button>
                          </Col>
                        </Row>
                      </>
                    ) : this.state.step == "2" ? (
                      <>
                        <div className="py-2">
                          <div className="formCard border rounded p-3 px-lg-4">
                            <div className="top text-center mb-4 pb-3 border-bottom">
                              <h4 className="m-0">Address Information</h4>
                            </div>
                            <Row>
                              <Col md="6" xs="12">
                                <div className="CstmAddress">
                                  <GoogleAutocomplete
                                    label="Address *"
                                    address={address}
                                    lat={lat}
                                    lng={lng}
                                    errors={errors}
                                    name="address"
                                    onChange={this.handleAddress}
                                    variant="outlined"
                                  />
                                </div>
                              </Col>
                              <Col md="6" xs="12">
                                <TEXTFIELD
                                  label="City *"
                                  value={city}
                                  name={"city"}
                                  onChange={this.handleChange}
                                  errors={errors}
                                  variant="outlined"
                                />
                              </Col>
                              <Col md="6" xs="12">
                                <TEXTFIELD
                                  label="State *"
                                  value={state}
                                  name={"state"}
                                  onChange={this.handleChange}
                                  errors={errors}
                                  variant="outlined"
                                />
                              </Col>
                            </Row>
                          </div>
                        </div>
                        <Row className={"btnWrpper mt-3"}>
                          <Col md="6" xs="12">
                            <Button
                              onClick={() => this.setState({ step: "1" })}
                              className="common-btn w-100 border-0 d-flex align-items-center justify-content-center"
                            >
                              Back
                            </Button>
                          </Col>
                          <Col md="6" xs="12">
                            <Button
                              disabled={!address || !city || !state}
                              onClick={() => this.setState({ step: "3" })}
                              className="common-btn w-100 border-0 d-flex align-items-center justify-content-center"
                            >
                              Next
                            </Button>
                          </Col>
                        </Row>
                      </>
                    ) : this.state.step == "3" ? (
                      <>
                        <div className="py-2">
                          <div className="formCard border rounded p-3 px-lg-4">
                            <div className="top text-center mb-4 pb-3 border-bottom">
                              <h4 className="m-0">Menu Information</h4>
                            </div>
                            <Row>
                              <Col md="6" xs="12">
                                <div className="cstmSelect">
                                  <SELECT
                                    label="Select Category"
                                    value={categoryIdRef}
                                    multiple={true}
                                    name={"categoryIdRef"}
                                    onChange={this.handleChange}
                                    data={categoryList}
                                    // errors={errors}
                                  />
                                </div>
                              </Col>
                              {restaurantType !== "freshMarket" && (
                                <Col md="6" xs="12">
                                  <div className="cstmSelect">
                                    <SELECT
                                      label="Select Menu Items"
                                      value={menuItemRef}
                                      multiple
                                      name={"menuItemRef"}
                                      onChange={this.handleChange}
                                      data={menuList}
                                      // errors={errors}
                                    />
                                  </div>
                                </Col>
                              )}
                            </Row>
                          </div>
                        </div>
                        <Row className={"btnWrpper mt-3"}>
                          <Col md="6" xs="12">
                            <Button
                              onClick={() => this.setState({ step: "2" })}
                              className="common-btn border-0 w-100 d-flex align-items-center justify-content-center"
                            >
                              Back
                            </Button>
                          </Col>
                          <Col md="6" xs="12">
                            <SUBMIT
                              title="Submit"
                              className="m-0"
                              disabled={disabled}
                            />
                          </Col>
                          {/* <Col md="6" xs="12">
                            <RESET onClick={this.reset} />
                          </Col> */}
                        </Row>
                      </>
                    ) : (
                      <></>
                    )}
                    <Row className={"mt-2"}>
                      <Col md="12" className={"d-flex justify-content-center"}>
                        <p className="m-0">
                          Already have an account?{" "}
                          <Link
                            href="#/signin"
                            className="form-link"
                            variant="body1"
                          >
                            {"Log In"}
                          </Link>
                        </p>
                      </Col>
                    </Row>
                  </form>

                  {/* </div> */}
                </div>
              </Grid>
            </Grid>
          </Container>
          `{" "}
          {/* <Grid
            item
            xs={false}
            sm={4}
            md={4}
            container
            justify="center"
            spacing={0}
            direction="column"
            alignItems="center"
            // justify="center"
          >
            <img
              src={require("assets/images/logo1.png")}
              className="logo-login"
            />
          </Grid> */}
          {/* <Grid
            item
            xs={12}
            sm={8}
            md={8}
            component={Paper}
            elevation={6}
            square
            alignContent="center"
            style={{ maxHeight: "100vh", overflow: "scroll" }}
          >
          

            <Grid container>
            <Grid item>
              <Link href="#/register" variant="body1">
                {"Don't have an account? Sign Up"}
              </Link>
            </Grid>
            </Grid>
          </Grid> */}
          `
        </section>
        <WhyOurBrand />
        <ImgWithText />
        <HowWeWork />
        <OurClient />
        <GetStartedSteps />
        <Faq />
        <Footer />
        <CssBaseline />
      </Grid>
    );
  }
}

const mapStateToProps = ({ auth }) => {
  const { loader, alertMessage, showMessage, authUser } = auth;
  return { loader, alertMessage, showMessage, authUser };
};

export default connect(mapStateToProps, {
  hideMessage,
  showAuthLoader,
  userSignInSuccess,
  hideAuthLoader,
})(withStyles(useStyles)(SignUp));
