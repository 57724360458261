import React from "react";
import { Redirect, Route, Switch, asyncComponent } from "../../../components";
const Customers = ({ match }) => {
  console.log(match.url, 65565);

  return (
    <div className="app-wrapper">
      <Switch>
        <Redirect exact from={`${match.url}/`} to={`${match.url}/list`} />
        <Route
          path={`${match.url}/list`}
          component={asyncComponent(() => import("./routes/list"))}
        />
        <Route
          path={`${match.url}/add/:cloneId?`}
          component={asyncComponent(() => import("./routes/add"))}
        />
        <Route
          path={`${match.url}/view/:editId`}
          component={asyncComponent(() => import("./routes/view"))}
        />
        <Route
          path={`${match.url}/import`}
          component={asyncComponent(() => import("./routes/import"))}
        />
        <Route
          path={`${match.url}/edit/:editId`}
          component={asyncComponent(() => import("./routes/add"))}
        />
        <Route
          component={asyncComponent(() =>
            import("app/routes/extraPages/routes/404")
          )}
        />
      </Switch>
    </div>
  );
};

export default Customers;
