import React from "react";
import { Container, Grid } from "@material-ui/core";
const WhyOurBrand = () => {
  return (
    <>
      <section className="IconSec py-5">
        <Container fixed>
          <Grid container spacing={3}>
            <Grid item md={12} direction="column" alignItems="center">
              <div className="section-header mb-3">
                <h2 className="m-0 fw-bold">
                  Why <span className="theme-clr">Tucson Eats</span>  
                </h2>
              </div>
            </Grid>
            <Grid item md={4} xs={12} direction="column" alignItems="center">
              <div className="cstmCard">
                <h4 className="m-0 pb-2">Deliver your way</h4>
                <p className="m-0">
                  Our offerings are flexible so you can customize them to your
                  needs. Get started with your delivery people or connect with
                  delivery people through the tucsoneats platform.
                </p>
              </div>
            </Grid>
            <Grid item md={4} xs={12} direction="column" alignItems="center">
              <div className="cstmCard">
                <h4 className="m-0 pb-2">Boost your visibility</h4>
                <p className="m-0">
                  Stand out with in-app marketing to reach even more customers
                  and increase sales.
                </p>
              </div>
            </Grid>
            <Grid item md={4} xs={12} direction="column" alignItems="center">
              <div className="cstmCard">
                <h4 className="m-0 pb-2">Connect with customers</h4>
                <p className="m-0">
                  Turn customers into regulars with actionable data insights,
                  respond to reviews or offer a loyalty program.
                </p>
              </div>
            </Grid>
          </Grid>
        </Container>
      </section>
    </>
  );
};
export default WhyOurBrand;
