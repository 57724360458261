import React from "react";
import { Container, Grid } from "@material-ui/core";

const ImgWithText = () => {
  return (
    <>
      <section className="imgWithText py-5">
        <Container fixed>
          <Grid
            container
            spacing={3}
            alignItems="center"
            justifyContent="space-between"
          >
            <Grid item md={5} xs={12}>
              <div className="section-header">
                <h2 className="fw-bold m-0 pb-2">
                  Unlock new <span className="theme-clr"> growth</span>
                </h2>
                <p className="py-2 m-0">
                  Thousands of Tucson Eats app users may be searching for
                  food in your area. By partnering with Tucson Eats and
                  adding your restaurant to the platform, we can help you reach
                  those users.
                </p>
              </div>
            </Grid>
            <Grid item md={7} xs={12} className="img-wrpper text-center">
              <img src="../images/iwt.png" alt="" className="img-fluid" />
            </Grid>
          </Grid>
        </Container>
      </section>
    </>
  );
};
export default ImgWithText;
