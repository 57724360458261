const Status={
    'created':"Registered",
    "registered":"Registered",
    'approved':"Approved",
    'rejected':"Rejected",
    'Online':'Online',
    'Offline':'Offline',
    true:"Yes",
    false:"No",
    "yes":"Yes",
    "no":"No",
    "approved":"Approved",
    'created':"New",
    'rejected':"Rejected",
    'active':"Active",
    'inactive':'In Active',
    'confirmed': 'Confirmed',
    'searching': 'Searching',
    'schedule':'scheduled',
    'started':'Started',
    'completed': 'Completed',
    'cancel': 'Cancelled',
    'Offline':'Offline',
    'active':'Active',
    "pending":"Pending",
    "cancelled":"Cancelled",
    "declined":"Declined"
}
export default Status;