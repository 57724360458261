import React, { Fragment } from "react";
import {
  withRouter,
  toast,
  Axios,
  CircularProgress,
  Row,
  Container,
  Col,
  Card,
  ApiUrl,
} from "../../../components";

import * as classNames from "classnames";
import { DragDropContext, Draggable, Droppable } from "react-beautiful-dnd";

const reorder = (list, startIndex, endIndex) => {
  console.log("list", list, "startIndex", startIndex, "endIndex", endIndex);
  const result = Array.from(list);
  const [removed] = result.splice(startIndex, 1);
  result.splice(endIndex, 0, removed);

  return result;
};
const {
  API_MENU_VIEW_URL,
  API_RESTAURANTS_MENU_UPDATE_URL,
  API_RESTAURANTS_ITEM_UPDATE_URL,
} = ApiUrl;

class ViewForm extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      editId: this.props.edit,
      details: "",
      extraDetail: {},
      menuItemRef: "",
      notfound: false,
      open: false,
      admin_pay_to_restaurant: "",
      items: [],
      menuItemData: [],
      menuItem: [],
      menuId: "",
    };
    this.onDragEnd = this.onDragEnd.bind(this);
    this.onDragMenuEnd = this.onDragMenuEnd.bind(this);
    console.log(props);
  }
  async componentWillMount() {
    this.menuCall();
  }
  menuCall() {
    this.setState({ loading: true });
    Axios.post(API_MENU_VIEW_URL)
      .then((response) => {
        response = response.data;
        console.log(
          "items data menuItemRef************",
          response?.data?.resdata?.menuItemRef
        );
        this.setState({
          menuItemData: response?.data?.resdata?.menuItemRef || [],
        });

        if (response.status === "success") {
          this.setState({
            details: response.data.resdata,
            extraDetail: response.data,
            menuItemRef: response.data.resdata.menuItemRef.map(
              (s) => s.menuItemName
            ),
            items: response.data.resdata.menuItemRef,
          });
        } else {
          this.setState({ notfound: true });
        }
      })
      .catch((error) => {
        toast.error("Customer not found. Invalid ID.");
      });
    this.setState({ loading: false });
  }
  onDragEnd(result, draggableId) {
    console.log(result, draggableId, "result");
    // `destination` is `undefined` if the item was dropped outside the list
    // In this case, do nothing
    if (!result.destination) {
      return;
    }

    const items = reorder(
      this.state.items,
      result.source.index,
      result.destination.index
    );
    console.log("data menu reorder", items);
    this.setState(
      {
        items,
      },
      () => {
        const data = {
          resturantId: localStorage.getItem("_id"),
          menuItemRef: this.state.items,
        };

        Axios.post(API_RESTAURANTS_MENU_UPDATE_URL, data)
          .then((res) => {
            let response = res.data;
            toast.success(response.message);
          })
          .catch((error) => {
            this.setState({
              alertVisible: true,
              alertClass: "danger",
              alertMessage: "Status Not Updated.",
            });
          });
      }
    );
  }
  onDragMenuEnd(result, draggableId) {
    console.log(result, draggableId, "result");
    var targetMenuId = result.source.droppableId;
    this.setState({ menuId: targetMenuId });
    const filteredItems =
      this.state?.menuItemData?.find((menu) => menu._id === targetMenuId)
        ?.items || [];
    console.log(filteredItems, "filteredItems");
    if (!result.destination) {
      return;
    }
    const menuItem = reorder(
      filteredItems,
      result.source.index,
      result.destination.index
    );
    console.log("data menuItem reorder", menuItem);
    this.setState(
      {
        menuItem,
      },

      () => {
        const data = {
          menuId: result?.source?.droppableId,
          items: menuItem,
        };
        console.log("menu data", data);
        Axios.post(API_RESTAURANTS_ITEM_UPDATE_URL, data)
          .then((res) => {
            console.log("menu res", res);
            this.menuCall();
            let response = res.data;
            toast.success(response.message);
          })
          .catch((error) => {
            this.setState({
              alertVisible: true,
              alertClass: "danger",
              alertMessage: "Status Not Updated.",
            });
          });
      }
    );
  }
  render() {
    const { loading } = this.state;

    return (
      <Container style={{ paddingBottom: "10px" }}>
        {loading && (
          <div className="text-center w-100">
            <CircularProgress />
          </div>
        )}
        <Row>
          <Col sm="12" className="vks">
            <Card>
              <div
                className="p-3 px-lg-4
              "
              >
                <div className="drag_drop">
                  <h1>Drag & Drop for change the positions of menu</h1>
                </div>
                {this.state.items.length > 0 ? (
                  <DragDropContext onDragEnd={this.onDragEnd}>
                    <Droppable droppableId="droppable" direction="horizontal">
                      {(provided, snapshot) => (
                          <div
                          ref={provided.innerRef}
                          className={classNames(
                            "list m-0",
                            snapshot.isDraggingOver && "draggingOver"
                          )}
                          style={{ overflowX: "scroll" }}
                          {...provided.droppableProps}
                        >
                          {this.state.items.map((item, index) => (
                            <div className="InnerCol position-relative">
                              <Draggable
                                key={index}
                                draggableId={`${index}-id`}
                                index={index}
                              >
                                {(provided, snapshot) => (
                                  <div className="ItemWrpper">
                                    <div
                                      className={classNames(
                                        "item",
                                        snapshot.isDragging && "dragging"
                                      )}
                                      style={provided.draggableProps.style}
                                      ref={provided.innerRef}
                                      {...provided.draggableProps}
                                      {...provided.dragHandleProps}
                                    >
                                      {item.menuItemName}
                                      {/* List item {item + 1} */}
                                    </div>
                                  </div>
                                )}
                              </Draggable>
                              <DragDropContext onDragEnd={this.onDragMenuEnd}>
                                <Droppable droppableId={item._id}>
                                  {(provided, snapshot) => (
                                    <div
                                      ref={provided.innerRef}
                                      className={classNames(
                                        "menulist position-relative",
                                        snapshot.isDraggingOver &&
                                          "draggingOver"
                                      )}
                                      {...provided.droppableProps}
                                    >
                                      {item?.items?.map((itemdata, i) => (
                                        <Draggable
                                          key={i}
                                          draggableId={`${i}-id`}
                                          index={i}
                                        >
                                          {(provided, snapshot) => (
                                            <div
                                              className={classNames(
                                                "menuitem mx-0",
                                                snapshot.isDragging &&
                                                  "dragging"
                                              )}
                                              style={
                                                provided.draggableProps.style
                                              }
                                              ref={provided.innerRef}
                                              {...provided.draggableProps}
                                              {...provided.dragHandleProps}
                                            >
                                              {itemdata?.itemName}
                                              {/* List item {item + 1} */}
                                            </div>
                                          )}
                                        </Draggable>
                                      ))}
                                      {provided.placeholder}
                                    </div>
                                  )}
                                </Droppable>
                              </DragDropContext>
                            </div>
                          ))}
                          {provided.placeholder}
                        </div>
                      )}
                    </Droppable>
                  </DragDropContext>
                ) : (
                  <div className="no_data">
                    <p>No data found</p>
                  </div>
                )}
              </div>
            </Card>
          </Col>
        </Row>
      </Container>
    );
  }
}

export default withRouter(ViewForm);
