import React, { Component, Fragment } from "react";
import {
  Container,
  connect,
  CircularProgress,
  ToastContainer,
  Logo,
  Row,
  Col,
} from "../components";
import SignIn from "./SignIn";
import SignUp from "./SignUp";

class Auth extends Component {
  constructor(props) {
    super(props);
    this.state = {
      form: "login",
    };
  }

  componentDidMount() {
    if (this.props.authUser !== null) {
      this.props.history.push("/app/dashboard/index");
    }
  }
  changeView = (view) => {
    this.setState({ form: view });
  };

  render() {
    const { form } = this.state;
    return (
      <Container fluid={true} className="auth-container">
        <Row className="sidebars">
          <Col md="4" xs="12" className="left-sidebar">
            <Row>
              <Col md="12" onClick={() => this.changeView("login")}>
                <h3 className={form === "login" ? "active" : ""}>Sign In</h3>
              </Col>
              <Col md="12" onClick={() => this.changeView("signup")}>
                <h3 className={form === "signup" ? "active" : ""}>Sign Up</h3>
              </Col>
              <Col
                md="12"
                className="logo-div"
                onClick={() => this.changeView("login")}
              >
                <img
                  src={require("assets/images/logo1.png")}
                  className="logo-login"
                />
              </Col>
            </Row>
          </Col>
          <Col md="8" xs="12">
            {form === "login" ? <SignIn /> : <SignUp />}
            <ToastContainer />
          </Col>
        </Row>
      </Container>
    );
  }
}

const mapStateToProps = ({ auth }) => {
  const { authUser } = auth;
  return { authUser };
};

export default connect(mapStateToProps, null)(Auth);
